import Axios from "../axios";

export const fetchMatches = async () => {
	try {
		const response = await Axios.get("/matches");
		return response.data;
	} catch (error) {
		return error;
	}
};

export const createMatch = async (payload) => {
	try {
		const response = await Axios.post("/matches/create", payload);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const deleteMatch = async (id) => {
	try {
		const response = await Axios.delete(`/matches/${id}`);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const updateMatch = async (id, payload) => {
	try {
		const response = await Axios.post(`/matches/${id}`, payload);
		return response.data;
	} catch (error) {
		return error;
	}
};
