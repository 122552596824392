import React, { useEffect, useState } from "react";
import Input from "../../Component/InputBox/InputBox";
import Logo from "../../asset/logo.png";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Button } from "@mui/material";
import { login } from "../../actions/users.actions";
import { useNavigate } from "react-router-dom";
import { userStore } from "../../zustand";

const LoginView = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // user store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  const Divider = styled("div")({
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "30px",
    color: "#707070",
    "&:before, &:after": {
      content: '""',
      flex: 1,
      borderBottom: "1px solid #707070",
    },
    "&:before": {
      marginRight: "10px",
    },
    "&:after": {
      marginLeft: "10px",
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    // Check if username or email is not empty
    if (!username && !email) {
      alert("Username Or email are required.");
      return;
    }

    setLoading(true);
    const loginRes = await login({
      username,
      email,
    });

    if (loginRes?.code !== "OK") {
      setLoading(false);
      // alert("Something went wrong in login!!!");
      return;
    }

    setUsername("");
    setEmail("");
    setLoading(false);
    navigate("/Inbox");
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        paddingInline: "20px",
      }}
    >
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          backgroundColor: "#2A2A2A",
          padding: {
            sm: "24px 40px 56px 40px",
            xs: "24px 20px 56px 20px",
          },
          flexDirection: "column",
          borderRadius: "10px",
          minWidth: {
            sm: "400px",
            xs: "100%",
          },
          "& .MuiFormControl-root": {
            width: "100%",
          },
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={Logo} alt="Logo" style={{ marginBottom: "10px" }} />
          <h2
            style={{
              color: "#0F0",
              textAlign: "center",
              fontFamily: "Arial",
              fontSize: "32px",
              fontWeight: "700",
              marginTop: "20px",
            }}
          >
            Login
          </h2>
        </div>
        <Input
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          customwidth="100%"
          marginBottom="20px"
          labelStyle={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Arial",
          }}
        />

        <Divider>OR</Divider>

        <Input
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          customwidth="100%"
          marginTop="15px"
          labelStyle={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Arial",
          }}
        />
        <Button
          type="submit"
          style={{
            width: "100%",
            padding: "10px",
            height: "44px",
            backgroundColor: "#39FF14",
            color: "#000",
            border: "none",
            borderRadius: "4px",
            cursor: loading ? "not-allowed" : "pointer",
            fontSize: "16px",
            marginTop: "15px",
            fontFamily: "DM Sans",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: "#000" }} />
          ) : (
            "Send Magic Link"
          )}
        </Button>
        <p
          style={{
            color: "#fff",
            textAlign: "center",
            marginTop: "20px",
            fontSize: "16px",
            fontFamily: "Arial",
            fontWeight: "400",
          }}
        >
          Don’t have an account ?
          <a
            href="/signup"
            style={{
              color: "#0F0",
              fontSize: "16px",
              fontWeight: "400",
              textDecoration: "none",
              marginLeft: "6px",
            }}
          >
            Sign up
          </a>
        </p>
      </Box>
    </div>
  );
};

export default LoginView;
