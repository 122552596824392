import React, { useState } from "react";
import styles from "./PlayerCard.module.css";
import { Info } from "../../Icon";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Locks, Popup, ReadMorePopup } from "../../Component";
import { Box } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { playerStore, userStore } from "../../zustand";
import { playerActions } from "../../actions";

const PlayerCard = ({ player }) => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { user } = userStore((state) => ({
    user: state.user,
  }));
  const { removePlayer } = playerStore((s) => ({
    removePlayer: s.removePlayer,
  }));

  const formattedDate = moment(player.createdAt)
    .tz("America/New_York")
    .format("h:mm A [ET] | ddd MMM D");

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const TEXT_LENGTH = 180;

  const getTextToRender = (text) => {
    if (text.length > TEXT_LENGTH) {
      return text.slice(0, TEXT_LENGTH) + "... ";
    }

    return text;
  };

  const handleDelete = async () => {
    // Delete Match API
    const deleteMatchRes = await playerActions.deletePlayer(player.id);

    if (deleteMatchRes.code === "OK") {
      removePlayer(player.id);
      alert("Player Deleted Successfully!!");
    }
    setIsOpen(false);
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <p className={styles.nflText}>{player.league}:</p>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <p className={styles.timeText}>{formattedDate}</p>
          {user?.role === "ADMIN" && (
            <Delete
              onClick={() => {
                setIsOpen(true);
              }}
              sx={{ color: "red", cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.player}>
          <div
            className={styles.title}
          >{`${player.players[0].name} - ${player.players[0].team}`}</div>
          <div className={styles.content}>
            <Info />
            <p className={styles.infoText}>
              {getTextToRender(player.players[0].reasoning)}
              {player.players[0].reasoning.length > TEXT_LENGTH && (
                <Link
                  className={styles.readMoreLink}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Read more
                </Link>
              )}
            </p>
          </div>
        </div>
        <div className={styles.overSection}>
          <Box sx={{ padding: "12px 16px" }}>
            <Locks lockValue={player.lockValue} />
          </Box>
          <div className={styles.overText}>{player.players[0].odds}</div>
        </div>
        <div className={styles.player}>
          <div
            className={styles.title}
          >{`${player.players[1].name} - ${player.players[1].team}`}</div>
          <div className={styles.content}>
            <Info />
            <p className={styles.infoText}>
              {getTextToRender(player.players[1].reasoning)}
              {player.players[1].reasoning.length > TEXT_LENGTH && (
                <Link
                  className={styles.readMoreLink}
                  onClick={() => {
                    setOpen1(true);
                  }}
                >
                  Read more
                </Link>
              )}
            </p>
          </div>
        </div>
        <div className={styles.overSection}>
          <Box sx={{ padding: "12px 16px" }}>
            <Locks lockValue={player.lockValue} />
          </Box>
          <div className={styles.overText}> {player.players[1].odds}</div>
        </div>
      </div>
      {open && (
        <ReadMorePopup
          open={open}
          handleClose={handleClose}
          text={player.players[0].reasoning}
          heading={player.league}
        />
      )}
      {open1 && (
        <ReadMorePopup
          open={open1}
          handleClose={handleClose1}
          text={player.players[1].reasoning}
          heading={player.league}
        />
      )}
      <Popup
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        title="Delete Player"
        message="Are you sure you want to delete this player?"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default PlayerCard;
