import React from "react";

import styles from "./backdrop.module.css";

const Backdrop = (props) => {
  const { open, setOpen, callback } = props;

  return open ? (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open);
        if (callback) callback();
      }}
    />
  ) : null;
};

export default Backdrop;
