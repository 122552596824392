import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  TableCell,
  Link,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import style from "./Profile.module.css";

import { styled } from "@mui/material/styles";
import { ProfileInfo, Popup, Backdrop } from "../../Component";
import logo from "../../asset/logo.png";
import { matchStore, userStore } from "../../zustand";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../actions";
import { fetchAllMatches } from "../../actions/users.actions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

const StatusIndicator = styled(Typography)(({ theme, status }) => ({
  display: "flex",
  alignItems: "center",
  color: status === "Solved" ? "green" : "yellow",
}));

const ProfileView = ({ rows }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // User Store
  const { user, setUser } = userStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  // Match Store
  const { matches, setMatches } = matchStore((state) => ({
    matches: state.matches,
    setMatches: state.setMatches,
  }));

  const handleLogoutClick = () => {
    setIsLogoutPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsLogoutPopupOpen(false);
  };

  const manageSubscription = async () => {
    if (user.subscriptionStatus !== "ACTIVE") {
      navigate("/subscription");
    } else {
      setLoading(true);
      const res = await userActions.createCheckout(user.stripeProductId);

      window.open(res.url);
      setLoading(false);
    }
  };

  // conevert date to July 12 format
  const convertDate = (date) => {
    const newDate = new Date(date * 1000);
    const day = newDate.getDate();
    const month = newDate.toLocaleString("default", { month: "long" });

    return `${month} ${day}`;
  };

  const getDaysLeft = (date) => {
    const newDate = new Date(date * 1000);
    const today = new Date();
    const diffTime = Math.abs(newDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  useEffect(() => {
    (async () => {
      const matches = await fetchAllMatches();
      setMatches(matches);
    })();
  }, []);

  return (
    <div className={style.Main}>
      <Box style={{ color: "#fff", paddingTop: isMobile ? "20px" : "61px" }}>
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <img src={logo} alt="logo" />
          </div>
        )}

        <Typography
          sx={{ color: "#fff" }}
          textAlign="center"
          padding={isMobile ? "1rem 0 0 0" : "2rem 0 0 0"}
          fontWeight={isMobile ? "500" : "700"}
          s
          fontSize={isMobile ? "24px" : "32px"}
        >
          User Profile
        </Typography>
      </Box>
      <div className={style.Container}>
        <div className={style.CardContainer}>
          <div className={style.Card}>
            <CardContent sx={{ padding: 0 }}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  fontSize="24px"
                  marginBottom="20px"
                >
                  Profile Stats
                </Typography>
                <p
                  style={{ color: "#2ECC71", cursor: "pointer" }}
                  onClick={() => setIsProfilePopupOpen(true)}
                >
                  Edit
                </p>
              </Box>

              <Typography fontWeight="400" fontSize="16px">
                Username: {user?.username}
              </Typography>
              <Typography fontWeight="400" fontSize="16px">
                {" "}
                Email: {user?.email}
              </Typography>
              <Typography fontWeight="400" fontSize="16px">
                {" "}
                First Name: {user?.firstName}
              </Typography>
              <Typography fontWeight="400" fontSize="16px">
                {" "}
                Last Name: {user?.lastName}
              </Typography>
              <Typography fontWeight="400" fontSize="16px">
                {" "}
                Phone Number: {user?.phoneNumber}
              </Typography>
            </CardContent>
          </div>

          {user.role !== "ADMIN" && (
            <div className={style.Card}>
              <CardContent sx={{ padding: "0 !important" }}>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  fontSize="24px"
                  marginBottom="15px"
                >
                  Previous Plays
                </Typography>
                <List style={{ maxHeight: 250, overflowY: "auto" }}>
                  {matches.length === 0 && <span>No Data</span>}
                  {matches?.map((match, i) => {
                    return (
                      <ListItem
                        className={style.text}
                        key={i}
                        style={{
                          color:
                            match.status === "PENDING"
                              ? "#fff"
                              : match.status === "LOST"
                              ? "#F57C7C"
                              : "#2ECC71",
                        }}
                      >
                        <ListItemText
                          primary={`${match.team1} vs ${match.team2} - ${match.winningTeam} - ${match.odds}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </CardContent>
            </div>
          )}

          {user.role !== "ADMIN" && (
            <div className={style.Card}>
              <CardContent sx={{ padding: 0 }}>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  fontSize="24px"
                  marginBottom="20px"
                >
                  Payment & Subscription
                </Typography>
                <Typography fontWeight="400" fontSize="16px" mb={2}>
                  Currently, your plan are on a monthly plan, which will be
                  charged $25 on {convertDate(user?.subscriptionEndDate)}.
                </Typography>
                <button
                  variant="contained"
                  className={style.edit}
                  style={{ width: 200 }}
                  onClick={manageSubscription}
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "#000" }} />
                  ) : (
                    "Manage Subscription"
                  )}
                </button>
              </CardContent>

              <Typography>
                Days Left in Subscription :{" "}
                {getDaysLeft(user.subscriptionEndDate)}
              </Typography>
            </div>
          )}

          {user.role !== "ADMIN" && (
            <div
              className={style.Card}
              style={{
                alignItems: "center",
              }}
            >
              <CardContent sx={{ padding: 0 }}>
                <Typography variant="h6" fontWeight="700" fontSize="24px">
                  Help & Support
                </Typography>
                <Typography
                  variant="h6"
                  fontFamily="DM Sans"
                  fontWeight="400"
                  fontSize="16px"
                  color="#666666"
                  marginBottom={"120px"}
                ></Typography>
                {/* <img src={image100} alt="image1" /> */}
                <button
                  variant="contained"
                  className={style.edit}
                  onClick={() => {
                    setIsFeedbackOpen(true);
                  }}
                >
                  Create Support Ticket
                </button>

                {isFeedbackOpen && (
                  <div>
                    <Backdrop
                      setOpen={setIsFeedbackOpen}
                      open={isFeedbackOpen}
                    />
                    <div className={style.feedbackPopUp}>
                      <iframe src="https://desk.zoho.in/support/fbw?formType=AdvancedWebForm&fbwId=edbsne55114a16f090cbb3c3d5b431fb5d4c7b543db6d737af5e5d761b35c757f8b79&xnQsjsdp=edbsn14ddcf6877feb4d25d68aeb9ed896760&mode=showNewWidget&displayType=iframe" />
                    </div>
                  </div>
                )}
                {/* <TableContainer className={style.Table}>
                <Table aria-label="ticket table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Ticket Number</StyledTableCell>
                      <StyledTableCell>Issues with</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.ticket}</TableCell>
                        <TableCell>{row.issue}</TableCell>
                        <TableCell>
                          <StatusIndicator status={row.status}>
                            <Box
                              border="1px solid #20DE33"
                              backgroundColor="#20DE33"
                              width="12px"
                              height="12px"
                              borderRadius="5px"
                            ></Box>
                          </StatusIndicator>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
              </CardContent>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "52px",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "#fff",
              fontFamily: "Work Sans",
              fontSize: "14px",
              fontWeight: "400",
              margin: 0,
            }}
          >
            Want to log out?
          </p>
          <button className={style.LogoutButton} onClick={handleLogoutClick}>
            Log out
          </button>
        </div>
      </div>
      <Popup
        title="Want to log out?"
        message="Are you sure you want to log out from EZPicks?"
        confirmText="Yes"
        cancelText="Cancel"
        open={isLogoutPopupOpen}
        handleClose={handleClosePopup}
        onConfirm={() => {
          setUser(null);
          localStorage.removeItem("token");
          navigate("/login");
        }}
      />
      <ProfileInfo
        open={isProfilePopupOpen}
        handleClose={() => setIsProfilePopupOpen(false)}
      />
    </div>
  );
};

export default ProfileView;
