import React from 'react'
import ProfileView from './ProfileView'

const ProfileComponet = () => {
    const rows = [
        { date: 'Jan 16 2024', ticket: '#342456235', issue: 'Help with Account Setup', status: 'In Progress' },
        { date: 'Jan 17 2024', ticket: '#342456235', issue: 'Can’t find export link', status: 'Solved' },
        { date: 'Jan 17 2024', ticket: '#342456235', issue: 'Billing Question', status: 'In Progress' },
        { date: 'Jan 17 2024', ticket: '#342456235', issue: '21000154678', status: 'In Progress' },
      ];
  return (
    <ProfileView rows={rows}/>
  )
}

export default ProfileComponet