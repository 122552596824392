import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userStore } from "../zustand";
import { useMediaQuery } from "@mui/material";
import NavBar from "./NavBar/NavBar";

const ProtectedRoute = ({ children }) => {
  const [isAllow, setIsAllow] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");

  // user Store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  useEffect(() => {
    if (user) {
      setIsAllow(true);
    } else {
      navigate("/login");
    }
  }, [user]);

  if (!isAllow) {
    return;
  }

  return (
    <>
      {!isMobile && <NavBar />}
      {children}
    </>
  );
};

export default ProtectedRoute;
