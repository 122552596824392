import React from "react";
import PlayerCard from "./PlayerCard/PlayerCard";
import { playerStore } from "../zustand";
import { Box } from "@mui/material";

const PlayerPanel = () => {
  // Player Store
  const { players } = playerStore((state) => ({
    players: state.players,
  }));

  return (
    <Box
      sx={{
        // maxHeight: "calc(100vh - 300px)",
        height: "100%",
        marginBottom: "90px",
      }}
    >
      {players.length ? (
        <div style={{ display: "flex", flexDirection: "column-reverse" }}>
          {players?.map((player, i) => {
            return <PlayerCard player={player} />;
          })}
        </div>
      ) : (
        <Box
          sx={{
            color: "white",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No data found
        </Box>
      )}
    </Box>
  );
};

export default PlayerPanel;
