import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Input from "../InputBox/InputBox";
import { matchActions } from "../../actions";
import { LoadingButton } from "@mui/lab";
import matchStore from "../../zustand/match.store";

const AddNewMatch = ({ open, handleClose }) => {
  // Match Store
  const { isMatchLoading, setIsMatchLoading, addMatches } = matchStore(
    (state) => ({
      isMatchLoading: state.isMatchLoading,
      setIsMatchLoading: state.setIsMatchLoading,
      addMatches: state.addMatches,
    })
  );

  const [form, setForm] = useState({
    league: "",
    team1: "",
    team2: "",
    lockValue: "",
    reasoning: "",
    winningTeam: "",
    odds: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleLockValueChange = (event) => {
    setForm({
      ...form,
      lockValue: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      form.league &&
      form.team1 &&
      form.team2 &&
      form.lockValue &&
      form.reasoning &&
      form.winningTeam &&
      form.odds
    ) {
      setIsMatchLoading(true);

      // Add New Match API
      const addMatchRes = await matchActions.createMatch({
        league: form.league,
        team1: form.team1,
        team2: form.team2,
        lockValue: Number(form.lockValue),
        reasoning: form.reasoning,
        winningTeam: form.winningTeam,
        odds: form.odds.toString(),
      });

      if (addMatchRes.code === "CREATED_SUCCESSFULLY") {
        addMatches(addMatchRes.data);
        handleClose();
        setForm({
          league: "",
          team1: "",
          team2: "",
          lockValue: "",
          reasoning: "",
          winningTeam: "",
          odds: "",
        });
      }
    } else {
      alert("All fields required.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-player-modal"
      aria-describedby="add-player-form"
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "584px",

          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#1B1C1E",
            padding: "32px 48px 40px 48px",
            borderRadius: "16px",
            color: "#fff",
            height: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#fff",
                fontFamily: "Neue Montreal",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Add New Match
            </Typography>
            <IconButton
              onClick={() => {
                handleClose();
                setForm({
                  league: "",
                  team1: "",
                  team2: "",
                  lockValue: "",
                  reasoning: "",
                  winningTeam: "",
                  odds: "",
                });
              }}
              sx={{ color: "#fff" }}
            >
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "14px",
              fontWeight: "400",
              opacity: "0.5",
            }}
          >
            Adding a new play will be visible to all users
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 4,
              width: "100%",
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Input
              label="League"
              name="league"
              value={form.league}
              onChange={handleChange}
              labelStyle={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "484px",
              }}
              style={{ width: "100%" }}
            />

            <Box sx={{ display: "flex", gap: 2 }}>
              <Input
                label="Team 1"
                name="team1"
                value={form.team1}
                onChange={handleChange}
                labelStyle={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Arial",
                }}
                inputStyle={{
                  backgroundColor: "#2A2A2A",
                }}
              />

              <Input
                label="Team 2"
                name="team2"
                value={form.team2}
                onChange={handleChange}
                labelStyle={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Arial",
                }}
                inputStyle={{
                  backgroundColor: "#2A2A2A",
                  width: "244px",
                }}
              />
            </Box>

            {/* Lock Value Dropdown */}
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <Typography
                sx={{
                  color: "#ccc",
                  marginBottom: "8px",
                  fontSize: "14px",
                }}
              >
                Lock Value
              </Typography>
              <Select
                fullWidth
                name="lockValue"
                value={form.lockValue}
                onChange={handleLockValueChange}
                displayEmpty
                sx={{
                  height: "45px",
                  color: "#fff",
                  backgroundColor: "#333",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#444",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#666",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#888",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#333",
                      color: "#fff",
                    },
                  },
                }}
              >
                <MenuItem value="3">3/3</MenuItem>
                <MenuItem value="2">2/3</MenuItem>
                <MenuItem value="1">1/3</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ marginBottom: "20px" }}>
              <Typography
                sx={{
                  color: "#ccc",
                  marginBottom: "8px",
                  fontSize: "14px",
                }}
              >
                Reasoning
              </Typography>
              {/* //! 500 chracter validation */}
              <Box
                component="textarea"
                name="reasoning"
                value={form.reasoning}
                onChange={handleChange}
                rows={4}
                sx={{
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "#333",
                  border: "1px solid #444",
                  borderRadius: "4px",
                  color: "#fff",
                  fontSize: "16px",
                  resize: "none",
                  "&:hover": {
                    borderColor: "#666",
                  },
                  "&:focus": {
                    borderColor: "#888",
                    outline: "none",
                  },
                }}
              />
            </Box>

            <Input
              label="Winning Team"
              name="winningTeam"
              value={form.winningTeam}
              onChange={handleChange}
              labelStyle={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "484px",
              }}
            />
            {/* //! number + 1 word validation  ,  55.5 Sachin   120 Sachin */}
            <Input
              label="Odds"
              name="odds"
              // type="number"
              value={form.odds}
              onChange={handleChange}
              labelStyle={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "484px",
              }}
              // startAdornment={
              //   <InputAdornment position="start" sx={{ color: "#fff" }}>
              //     <Typography
              //       variant="span"
              //       sx={{
              //         paddingRight: "10px",
              //         borderRight: "1px solid white",
              //         marginRight: "10px",
              //       }}
              //     >
              //       Over
              //     </Typography>
              //   </InputAdornment>
              // }
            />

            <Button
              variant="contained"
              style={{
                backgroundColor: "#0F0",
                marginTop: "12px",
                color: "#111",
                textTransform: "none",
                fontFamily: "DM Sans",
                fontWeight: "500",
                fontSize: "16px",
                borderRadius: "5px",
                height: "44px",
              }}
              type="submit"
            >
              {isMatchLoading ? (
                <CircularProgress size={24} style={{ color: "#000" }} />
              ) : (
                "Add"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNewMatch;
