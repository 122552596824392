import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  DashboardComponent,
  LoginComponent,
  NotificationComponet,
  ProfileComponet,
  SingUpComponent,
  Subscription,
} from "./Page";
import InboxComponent from "./Page/Inbox/InboxComponent";
import { BottomBar, LoadingPage, ProtectedRoute } from "./Component";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import Axios from "./axios";
import {
  fetchUser,
  refreshToken,
  verifyEmailToken,
} from "./actions/users.actions";
import { userStore } from "./zustand";
import History from "./Page/History/History";

function App() {
  const navigate = useNavigate();
  const [showHeader, setShowHeader] = useState(true);
  const [loader, setLoader] = useState(true);

  // User Store
  const { user, setUser } = userStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  const isDesktopOrLaptop = useMediaQuery("(min-width: 924px)");
  const isMobile = useMediaQuery("(max-width:767px)");

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const tokenParams = searchParams.get("token");

  const fetchUserDetails = async (id, token) => {
    try {
      const userRes = await fetchUser(id);

      if (userRes.code === "OK") {
        setLoader(false);
        setUser(userRes.data);

        if (
          userRes.data.subscriptionStatus !== "ACTIVE" &&
          userRes.data.role !== "ADMIN"
        ) {
          navigate("/subscription");
        }
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    let res;
    (async () => {
      setLoader(true);
      if (tokenParams) {
        res = await verifyEmailToken({ token: tokenParams });
      } else {
        res = await refreshToken();
      }

      await fetchUserDetails(res?.uid, res?.accessToken);
      setLoader(false);
    })();
  }, []);

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      setShowHeader(false);
    }
  }, [location]);

  // Refresh Token
  // useEffect(() => {
  //   if (tokenParams) return;
  // }, []);

  if (loader) {
    return <LoadingPage />;
  }

  return (
    <div
      style={{ background: "#000", minHeight: "100vh" }}
      id={isDesktopOrLaptop ? "App" : "AppMObile"}
    >
      {!loader && (
        <>
          <Routes>
            <Route exact path="/login" element={<LoginComponent />} />
            <Route exact path="/signup" element={<SingUpComponent />} />
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute>
                  <DashboardComponent />
                </ProtectedRoute>
              }
            />
            <Route exact path="/inbox" element={<InboxComponent />} />
            <Route
              exact
              path="/history"
              element={
                <ProtectedRoute>
                  <History />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfileComponet />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notification"
              element={
                <ProtectedRoute>
                  <NotificationComponet />
                </ProtectedRoute>
              }
            />

            <Route
              path="/subscription"
              element={
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              }
            />
          </Routes>
        </>
      )}

      {showHeader && isMobile && <BottomBar />}
    </div>
  );
}

export default App;
