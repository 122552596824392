import React, { useEffect, useState } from "react";
import SingUpView from "./SingUpView";

import { useNavigate } from "react-router-dom";
import { register } from "../../actions/users.actions";
import { userStore } from "../../zustand";

const SingUpComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // user store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  const [form, setForm] = useState({
    email: "",
    username: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if username or email is not empty
    if (!form.username || !form.email) {
      alert("Username And email are required.");
      return;
    }

    setLoading(true);
    const isSuccess = await register({
      email: form.email,
      username: form.username,
    });

    setLoading(false);
    if (isSuccess) {
      return navigate("/Inbox");
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <SingUpView
      handleChange={handleChange}
      form={form}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default SingUpComponent;
