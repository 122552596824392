import { playerService } from "../services";

export const fetchPlayers = async () => {
  try {
    const response = await playerService.fetchPlayers();
    return response;
  } catch (error) {
    return error;
  }
};

export const addPlayer = async (payload) => {
  try {
    const response = await playerService.addPlayer(payload);

    if (response.code !== "CREATED_SUCCESSFULLY") {
      alert(response.response.data.message);
      return null;
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const deletePlayer = async (id) => {
  try {
    const response = await playerService.deletePlayer(id);

    if (response?.code !== "OK") {
      alert(response.response.data.message);
      return false;
    }

    return response;
  } catch (error) {
    return error;
  }
};
