import React, { useState } from "react";
import { Box, Typography, Switch, useMediaQuery } from "@mui/material";
import { updateUser } from "../../actions/users.actions";
import { userStore } from "../../zustand";

const NotificationView = () => {
  // User Store
  const { user, setUser } = userStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  //!issue
  const handleSwitchChange = async (e, permission, type) => {
    let userRes;
    const usersEmailPermission = user?.email_permissions;
    const usersPhonePermission = user?.phone_permissions;

    if (e.target.checked) {
      if (type === "email") {
        setUser({
          ...user,
          email_permissions: [...usersEmailPermission, permission],
        });

        userRes = await updateUser(user.id, {
          email_permissions: [...usersEmailPermission, permission],
        });

        setUser({ ...user, ...userRes.data });
      } else if (type === "phone") {
        setUser({
          ...user,
          phone_permissions: [...usersPhonePermission, permission],
        });

        userRes = await updateUser(user.id, {
          phone_permissions: [...usersPhonePermission, permission],
        });
        setUser({ ...user, ...userRes.data });
      }
    } else {
      if (type === "email") {
        setUser({
          ...user,
          email_permissions: usersEmailPermission.filter(
            (item) => item !== permission
          ),
        });

        userRes = await updateUser(user.id, {
          email_permissions: usersEmailPermission.filter(
            (item) => item !== permission
          ),
        });

        setUser({ ...user, ...userRes.data });
      } else if (type === "phone") {
        setUser({
          ...user,
          phone_permissions: usersPhonePermission.filter(
            (item) => item !== permission
          ),
        });

        userRes = await updateUser(user.id, {
          phone_permissions: usersPhonePermission.filter(
            (item) => item !== permission
          ),
        });
        setUser({ ...user, ...userRes.data });
      }
    }
  };

  const switchStyles = {
    "& .MuiSwitch-switchBase": {
      color: "#FFFFFF", // Inactive state ball color (white)
      "&.Mui-checked": {
        color: "#2ECC71", // Active state ball color (green)
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#424242", // Inactive state background color (black)
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#2ECC71", // Active state background color (light green)
    },
  };

  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <div>
      <Box>
        <Typography
          sx={{ color: "#fff" }}
          textAlign="center"
          paddingTop={isMobile ? "30px" : "65px"}
          fontWeight="700"
          fontSize="32px"
        >
          Notifications
        </Typography>
      </Box>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: isMobile ? "20px" : "40px",
        }}
      >
        <div
          style={{
            padding: "0px 24px",
            borderRadius: "12px",
            width: "476px",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "13px",
          }}
        >
          {/* Header Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 24px",
              gap: "12px",
            }}
          >
            <Typography style={{ color: "#fff", fontWeight: "bold", flex: 1 }}>
              Setting
            </Typography>
            <Typography
              style={{ color: "#fff", textAlign: "center", width: "58px" }}
            >
              Email
            </Typography>
            <Typography
              style={{ color: "#fff", textAlign: "center", width: "58px" }}
            >
              Phone
            </Typography>
          </div>

          {/* Data Rows Section */}
          <div
            style={{
              padding: "10px 24px",
              backgroundColor: "#191919",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
                gap: "12px",
              }}
            >
              <Typography
                style={{
                  color: "#E4E4E4",
                  fontSize: "14px",
                  fontWeight: "500",
                  flex: 1,
                  borderBottom: "1px solid #313131",
                  paddingBottom: "10px",
                }}
              >
                Payment Notification
              </Typography>
              <Switch
                sx={switchStyles}
                checked={user?.email_permissions?.includes("PAYMENT")}
                onChange={(e) => handleSwitchChange(e, "PAYMENT", "email")}
              />
              <Switch
                sx={switchStyles}
                checked={user?.phone_permissions?.includes("PAYMENT")}
                onChange={(e) => handleSwitchChange(e, "PAYMENT", "phone")}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
                gap: "12px",
              }}
            >
              <Typography
                style={{
                  color: "#E4E4E4",
                  fontSize: "14px",
                  fontWeight: "500",
                  flex: 1,
                  borderBottom: "1px solid #313131",
                  paddingBottom: "10px",
                }}
              >
                New Plays Notification
              </Typography>
              <Switch
                sx={switchStyles}
                checked={user?.email_permissions?.includes("NEW_PLAY")}
                onChange={(e) => handleSwitchChange(e, "NEW_PLAY", "email")}
              />
              <Switch
                sx={switchStyles}
                checked={user?.phone_permissions?.includes("NEW_PLAY")}
                onChange={(e) => handleSwitchChange(e, "NEW_PLAY", "phone")}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
                gap: "12px",
              }}
            >
              <Typography
                style={{
                  color: "#E4E4E4",
                  fontSize: "14px",
                  fontWeight: "500",
                  flex: 1,
                }}
              >
                Subscription
              </Typography>
              <Switch
                sx={switchStyles}
                checked={user?.email_permissions?.includes("SUBSCRIPTION")}
                onChange={(e) => handleSwitchChange(e, "SUBSCRIPTION", "email")}
              />
              <Switch
                sx={switchStyles}
                checked={user?.phone_permissions?.includes("SUBSCRIPTION")}
                onChange={(e) => handleSwitchChange(e, "SUBSCRIPTION", "phone")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationView;
