import { create } from "zustand";

const matchStore = create((set) => ({
  matches: [],
  isMatchLoading: false,
  setIsMatchLoading: (val) =>
    set((state) => ({ ...state, isMatchLoading: val })),
  setMatches: (data) => set(() => ({ matches: data, isMatchLoading: false })),
  addMatches: (match) =>
    set((state) => ({
      ...state,
      matches: [...state.matches, match],
      isMatchLoading: false,
    })),
  removeMatch: (id) =>
    set((state) => ({
      ...state,
      matches: state.matches.filter((item) => item.id !== id),
      isMatchLoading: false,
    })),

  updateMatch: (id, updatedMatch) =>
    set((state) => ({
      ...state,
      matches: state.matches.map((match) =>
        match.id === id ? { ...match, ...updatedMatch } : match
      ),
      isMatchLoading: false,
    })),
}));

export default matchStore;
