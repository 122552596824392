import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Input from "../../Component/InputBox/InputBox";
import { userStore } from "../../zustand";
import { updateUser } from "../../actions/users.actions";

const ProfileInfo = ({ open, handleClose }) => {
  // User Store
  const { user, setUser } = userStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  const [form, setForm] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [error, setError] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setForm({
        username: user?.username || "",
        email: user?.email || "",
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        phone: user?.phoneNumber || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError({
      ...error,
      [name]: "",
    });
    setForm({
      ...form,
      [name]: value,
    });
  };

  const validation = () => {
    let errors = {};

    const phoneRegex = /^\d{10}$/;

    if (!phoneRegex.test(form["phone"])) {
      errors["phone"] = "Phone Number isn't valid";
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return true;
    }

    setError({});
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorRes = validation();

    if (errorRes) {
      return;
    }

    // Update the user
    setIsLoading(true);
    const updateUserRes = await updateUser(user?.id, {
      firstName: form.firstName,
      lastName: form.lastName,
      phoneNumber: form.phone,
      phoneCode: "+91",
    });
    setIsLoading(false);

    if (updateUserRes.code !== "OK") {
      alert("Something wrong");
      return;
    }

    alert(updateUserRes.message);
    setUser(updateUserRes.data);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-player-modal"
      aria-describedby="add-player-form"
      sx={{ paddingInline: "20px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingInline: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#1B1C1E",
            // padding: "32px 48px 40px 48px",

            padding: {
              sm: "24px 40px 0px 40px",
              xs: "16px 28px 0px 28px",
            },
            borderRadius: "16px",
            maxWidth: "540px",
            width: "calc(100% - 50px)",
            height: { sm: "75vh", xs: "80vh" },
            overflow: "auto",
            color: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#fff",
                fontFamily: "Neue Montreal",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Edit Profile Info
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
              <Close />
            </IconButton>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 3,
              width: "100%",
              "& .MuiFormControl-root": {
                width: "100%",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <Input
              label="Username"
              name="username"
              sx={{
                width: "100%",
                height: "40px",
                marginBottom: "24px",
              }}
              labelStyle={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "100%",
                color: "white",
              }}
              disabled={true}
              value={form.username}
              onChange={handleChange}
            />

            <Input
              label="Email"
              name="email"
              labelStyle={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "100%",
              }}
              sx={{
                width: "100%",
                height: "40px",
                marginBottom: "24px",
              }}
              disabled={true}
              value={form.email}
              onChange={handleChange}
            />

            <Input
              label="First Name"
              name="firstName"
              labelStyle={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "100%",
              }}
              sx={{
                height: "40px",
                marginBottom: "24px",
              }}
              value={form.firstName}
              onChange={handleChange}
            />

            <Input
              label="Last Name"
              name="lastName"
              labelStyle={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "100%",
              }}
              sx={{
                height: "40px",
                marginBottom: "24px",
              }}
              value={form.lastName}
              onChange={handleChange}
            />

            <Input
              label="Phone Number"
              name="phone"
              labelStyle={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "100%",
              }}
              sx={{
                height: "40px",
              }}
              value={form.phone}
              onChange={handleChange}
            />
            <p style={{ color: "red", marginBottom: "16px", fontSize: 12 }}>
              {error.phone}
            </p>

            <Button
              variant="contained"
              style={{
                backgroundColor: "#0F0",
                marginTop: "12px",
                color: "#111",
                textTransform: "none",
                fontFamily: "DM Sans",
                fontWeight: "500",
                fontSize: "16px",
                borderRadius: "5px",
                height: "44px",
              }}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress size={24} style={{ color: "#000" }} />
              ) : (
                "Add"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProfileInfo;
