import * as React from "react";
const CheckCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M21.5 7.00003L9.5 19L4 13.5L5.41 12.09L9.5 16.17L20.09 5.59003L21.5 7.00003Z"
      fill="white"
    />
  </svg>
);
export default CheckCircle;
