import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import MatchPanel from "./MatchPanel";
import PlayerPanel from "./PlayerPanel";
import { matchStore, playerStore, userStore } from "../zustand";
import { matchActions, playerActions } from "../actions";
import { Add } from "@mui/icons-material";
import AddPlayerPopup from "./AddPlayerPopup/AddPlayerPopup";
import AddNewMatch from "./AddNewMatch/AddNewMatch";

const MatchPlayerTab = () => {
  const [value, setValue] = React.useState("1");

  // User Store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  // Match Store
  const { setMatches, setIsMatchLoading } = matchStore((state) => ({
    setMatches: state.setMatches,
    setIsMatchLoading: state.setIsMatchLoading,
  }));

  // Player Store
  const { setPlayers, setIsPlayerLoading } = playerStore((state) => ({
    setPlayers: state.setPlayers,
    setIsPlayerLoading: state.setIsPlayerLoading,
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openAddPlayerPopup, setOpenAddPlayerPopup] = useState(false);
  const [openAddMatchPopup, setOpenAddMatchPopup] = useState(false);

  const handleOpenAddPlayerPopup = () => {
    setOpenAddPlayerPopup(true);
  };

  const handleCloseAddPlayerPopup = () => {
    setOpenAddPlayerPopup(false);
  };

  const handleOpenAddMatchPopup = () => {
    setOpenAddMatchPopup(true);
  };

  const handleCloseAddMatchPopup = () => {
    setOpenAddMatchPopup(false);
  };

  // Fetch the matches and players and store it into store
  useEffect(() => {
    (async () => {
      setIsMatchLoading(true);
      setIsPlayerLoading(true);
      const matchPromise = matchActions.fetchMatches();
      const playerPromise = playerActions.fetchPlayers();

      const promises = [matchPromise, playerPromise];
      const finalResponse = await Promise.allSettled(promises);

      if (
        finalResponse[0].status !== "fulfilled" ||
        finalResponse[0].value.code !== "OK" ||
        finalResponse[1].status !== "fulfilled" ||
        finalResponse[1].value.code !== "OK"
      ) {
        alert("Something went wrong while fetching the Match & Players Data.");
      }

      if (
        finalResponse[0].status === "fulfilled" &&
        finalResponse[0].value.code === "OK"
      ) {
        setMatches(finalResponse[0].value.data);
      }

      if (
        finalResponse[1].status === "fulfilled" &&
        finalResponse[1].value.code === "OK"
      ) {
        setPlayers(finalResponse[1].value.data);
      }
    })();
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "relative",
            zIndex: 9,
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              backgroundColor: "#323232",
              borderRadius: "100px",
              padding: "5px",
              overflow: "hidden",
              marginBottom: 0,

              "& .MuiTab-root": {
                color: "#fff",
                position: "relative",
                zIndex: 1,
                textTransform: "capitalize",
                fontSize: "16px",
              },
              "& .Mui-selected": {
                color: "#fff !important",
              },
              "& .MuiTabs-indicator": {
                height: "100%",
                borderRadius: "100px",
                backgroundColor: "#000",
                zIndex: 0,
              },
            }}
          >
            <Tab label="Games" value="1" />
            <Tab label="Players" value="2" />
          </TabList>
        </Box>

        {user?.role === "ADMIN" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                sm: "row",
                xs: "column",
              },
              justifyContent: "flex-start",
              position: {
                md: "absolute",
                xs: "relative",
              },
              gap: "10px",
              top: 5,
              right: {
                md: 25,
                xs: 0,
              },
              zIndex: 9,
            }}
          >
            {value === "2" && (
              <Button
                onClick={handleOpenAddPlayerPopup}
                startIcon={<Add />}
                sx={{
                  padding: "8px 16px",
                  backgroundColor: "#0F0",
                  color: "#111",
                  fontWeight: "700",
                  fontFamily: "Arial",
                  textTransform: "none",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#0F0",
                  },
                }}
              >
                Add Players
              </Button>
            )}

            {value === "1" && (
              <Button
                onClick={handleOpenAddMatchPopup}
                startIcon={<Add />}
                sx={{
                  padding: "8px 16px",
                  backgroundColor: "#0F0",
                  color: "#111",
                  fontWeight: "700",
                  fontFamily: "Arial",
                  textTransform: "none",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#0F0",
                  },
                }}
              >
                Add Game
              </Button>
            )}
          </Box>
        )}

        <Box
          sx={{
            marginTop: "20px",
            width: "100%",
          }}
        >
          <TabPanel value="1">
            <MatchPanel />
          </TabPanel>
          <TabPanel value="2">
            <PlayerPanel />
          </TabPanel>
        </Box>

        {/* Add Player Popup */}
        <AddPlayerPopup
          open={openAddPlayerPopup}
          handleClose={handleCloseAddPlayerPopup}
        />

        {/* Add Match Popup */}
        <AddNewMatch
          open={openAddMatchPopup}
          handleClose={handleCloseAddMatchPopup}
        />
      </TabContext>
    </div>
  );
};

export default MatchPlayerTab;
