import * as React from "react";
const Lock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={16}
    viewBox="0 0 12 16"
    fill="none"
    {...props}
  >
    <path
      d="M1.5 16C1.0875 16 0.7345 15.8509 0.441 15.5528C0.1475 15.2546 0.0005 14.8957 0 14.4762V6.85714C0 6.4381 0.147 6.07949 0.441 5.78133C0.735 5.48317 1.088 5.33384 1.5 5.33333H2.25V3.80952C2.25 2.75556 2.61575 1.85727 3.34725 1.11467C4.07875 0.372064 4.963 0.000508457 6 5.20071e-07C7.037 -0.000507416 7.9215 0.371048 8.6535 1.11467C9.3855 1.85829 9.751 2.75657 9.75 3.80952V5.33333H10.5C10.9125 5.33333 11.2657 5.48267 11.5597 5.78133C11.8537 6.08 12.0005 6.4386 12 6.85714V14.4762C12 14.8952 11.8532 15.2541 11.5597 15.5528C11.2662 15.8514 10.913 16.0005 10.5 16H1.5ZM6 12.1905C6.4125 12.1905 6.76575 12.0414 7.05975 11.7432C7.35375 11.4451 7.5005 11.0862 7.5 10.6667C7.4995 10.2471 7.35275 9.88851 7.05975 9.59086C6.76675 9.29321 6.4135 9.14387 6 9.14286C5.5865 9.14184 5.2335 9.29117 4.941 9.59086C4.6485 9.89054 4.5015 10.2491 4.5 10.6667C4.4985 11.0842 4.6455 11.443 4.941 11.7432C5.2365 12.0434 5.5895 12.1925 6 12.1905ZM3.75 5.33333H8.25V3.80952C8.25 3.1746 8.03125 2.63492 7.59375 2.19048C7.15625 1.74603 6.625 1.52381 6 1.52381C5.375 1.52381 4.84375 1.74603 4.40625 2.19048C3.96875 2.63492 3.75 3.1746 3.75 3.80952V5.33333Z"
      fill="#00FF00"
    />
  </svg>
);

export default Lock;
