import React from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  FormControl,
  InputAdornment,
  InputBase,
  InputLabel,
} from "@mui/material";

const BootstrapInput = styled(InputBase)(
  ({ theme, customwidth, customheight }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-root": {
      width: "100%",
      borderRadius: 5,
      position: "relative",
      backgroundColor: "#333",
      border: "none",
      color: "white",
      // width: customwidth || "auto", // Use custom width if provided
      // height: customheight || "auto", // Use custom height if provided
      padding: "10px",

      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    "& .MuiInputBase-input": {
      backgroundColor: "transparent !important",
    },
    "& .Mui-disabled": {
      "-webkit-text-fill-color": "rgba(255,255,255,.2) !important",
    },
  })
);

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.mode === "light" ? "#555" : "#fff", // Custom color for label
  fontWeight: 500,
  fontSize: 14,
  "&.Mui-focused": {
    color: theme.palette.primary.main, // Change color on focus
  },
}));

const InputBox = ({
  label,
  value,
  onChange,
  type = "text",
  customwidth,
  customheight,
  marginBottom,
  labelStyle,
  inputStyle,
  ...props
}) => {
  return (
    <div style={{ marginBottom, flex: 1 }}>
      <FormControl variant="standard" sx={{ width: "100%" }}>
        <StyledInputLabel style={labelStyle} shrink htmlFor="bootstrap-input">
          {label}
        </StyledInputLabel>
        <BootstrapInput
          value={value}
          onChange={onChange}
          type={type}
          id="bootstrap-input"
          customwidth={customwidth}
          customheight={customheight}
          inputProps={{ style: inputStyle }}
          {...props}
          //   slotProps={{
          //     input: {
          //       startAdornment: "kg",
          //     },
          //   }}
        />
      </FormControl>
    </div>
  );
};

export default InputBox;
