import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingPage = () => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				background: "#000",
				top: 0,
				left: 0,
				position: "fixed",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				zIndex: 0,
			}}
		>
			<CircularProgress sx={{ color: "white" }} />
		</Box>
	);
};

export default LoadingPage;
