import Axios from "../axios";

export const fetchPlayers = async () => {
	try {
		const response = await Axios.get("/players");
		return response.data;
	} catch (error) {
		return error;
	}
};

export const addPlayer = async (payload) => {
	try {
		const response = await Axios.post("/players/create", payload);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const updatePlayer = async (playerId, payload) => {
	try {
		const response = await Axios.post(`/players/${playerId}`, payload);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const deletePlayer = async (playerId) => {
	try {
		const response = await Axios.delete(`/players/${playerId}`);
		return response.data;
	} catch (error) {
		return error;
	}
};
