import React from "react";

import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CloseSquare } from "../../Icon";

const ReadMorePopup = ({ open, handleClose, text, heading }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-player-modal"
      aria-describedby="add-player-form"
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "584px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#1B1C1E",
            padding: isMobile ? "16px 24px" : "32px 48px 40px 48px",
            borderRadius: "16px",
            color: "#fff",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              minWidth: isMobile ? 200 : 300,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#fff",
                fontFamily: "Work Sans",
                fontSize: isMobile ? "24px" : "32px",
                fontWeight: "700",
                lineHeight: isMobile ? "36px" : "51.2px",
              }}
            >
              {heading}
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
              <CloseSquare />
            </IconButton>
          </Box>
          <p
            style={{
              color: "#FFF",
              fontFamily: "Work Sans",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "14px",
            }}
          >
            {text}
          </p>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#2ECC71",
              padding: "10px 40px",
              marginTop: "32px",
              color: "#000",
              textTransform: "none",
              fontFamily: "DM Sans",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              borderRadius: "100px",
              height: isMobile ? "32px" : "48px",
              width: "100%",
            }}
            type="submit"
            onClick={handleClose}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReadMorePopup;
