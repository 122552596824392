import React, { useEffect } from "react";

import { LoadingPage, SubscriptionCard } from "../../Component";
import { getPricing } from "../../actions/users.actions";

import styles from "./Subscription.module.css";

const Subscription = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [pricing, setPricing] = React.useState([]);

  useEffect(() => {
    (async () => {
      const res = await getPricing();

      setIsLoading(false);
      setPricing(res);
    })();
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.Subscription}>
      <p className={styles.text}>
        {`Your subscription has expired.\nPlease renew your plan.`}
      </p>

      <div className={styles.SubscriptionCard}>
        {pricing.map((data) => (
          <SubscriptionCard data={data} />
        ))}
      </div>
    </div>
  );
};

export default Subscription;
