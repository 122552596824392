import * as React from "react";
const Info = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={28}
    viewBox="0 0 27 28"
    fill="none"
    {...props}
  >
    <path
      d="M13.5001 2.1875C16.6332 2.1875 19.638 3.43212 21.8534 5.64755C24.0688 7.86298 25.3135 10.8677 25.3135 14.0008C25.3135 17.1339 24.0688 20.1387 21.8534 22.3541C19.638 24.5696 16.6332 25.8142 13.5001 25.8142C10.367 25.8142 7.36225 24.5696 5.14682 22.3541C2.93138 20.1387 1.68677 17.1339 1.68677 14.0008C1.68677 10.8677 2.93138 7.86298 5.14682 5.64755C7.36225 3.43212 10.367 2.1875 13.5001 2.1875ZM15.272 9.44037C16.1495 9.44037 16.8616 8.83119 16.8616 7.92837C16.8616 7.02556 16.1478 6.41638 15.272 6.41638C14.3945 6.41638 13.6857 7.02556 13.6857 7.92837C13.6857 8.83119 14.3945 9.44037 15.272 9.44037ZM15.5808 18.9359C15.5808 18.7554 15.6432 18.2862 15.6078 18.0196L14.2207 19.616C13.9338 19.9181 13.5744 20.1273 13.4056 20.0716C13.329 20.0435 13.2651 19.9889 13.2251 19.9177C13.1852 19.8466 13.172 19.7635 13.1879 19.6835L15.4998 12.38C15.6888 11.4536 15.1691 10.6081 14.0671 10.5001C12.9044 10.5001 11.1933 11.6797 10.1521 13.1765C10.1521 13.3554 10.1184 13.8009 10.1538 14.0675L11.5392 12.4694C11.8261 12.1707 12.1602 11.9598 12.329 12.0172C12.4121 12.047 12.4803 12.1083 12.5187 12.1878C12.5572 12.2673 12.563 12.3587 12.5349 12.4424L10.2432 19.7105C9.9783 20.561 10.4795 21.3946 11.6945 21.5836C13.4832 21.5836 14.5396 20.4328 15.5825 18.9359H15.5808Z"
      fill="#606060"
    />
  </svg>
);
export default Info;
