import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  InputAdornment,
} from "@mui/material";
import { KeyboardArrowDown, Close } from "@mui/icons-material";
import Input from "../../Component/InputBox/InputBox";
import { Select, MenuItem, FormControl } from "@mui/material";
import { playerStore } from "../../zustand";
import { playerActions } from "../../actions";

const AddPlayerPopup = ({ open, handleClose }) => {
  // Player Store
  const { setIsPlayerLoading, addPlayer } = playerStore((state) => ({
    setIsPlayerLoading: state.setIsPlayerLoading,
    addPlayer: state.addPlayer,
  }));

  const [players, setPlayers] = useState([
    { player: "", team: "", reasoning: "", odds: "" },
    { player: "", team: "", reasoning: "", odds: "" },
  ]);

  const [form, setForm] = useState({
    league: "",
    player1: "",
    team1: "",
    reasoning1: "",
    odds1: "",
    player2: "",
    team2: "",
    reasoning2: "",
    odds2: "",
    lockValue: "",
  });

  const handleInputChange = (e, index, field) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = e.target.value;
    setPlayers(updatedPlayers);

    // Update the form state with the input values
    setForm((prevForm) => ({
      ...prevForm,
      [`player${index + 1}`]: updatedPlayers[index].player,
      [`team${index + 1}`]: updatedPlayers[index].team,
      [`reasoning${index + 1}`]: updatedPlayers[index].reasoning,
      [`odds${index + 1}`]: updatedPlayers[index].odds,
    }));
  };

  const handleFormChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Add New Player
    setIsPlayerLoading(true);

    // Add New Match API
    const addPlayerRes = await playerActions.addPlayer({
      league: form.league,
      players: [
        {
          name: form.player1,
          team: form.team1,
          reasoning: form.reasoning1,
          odds: form.odds1,
        },
        {
          name: form.player2,
          team: form.team2,
          reasoning: form.reasoning2,
          odds: form.odds2,
        },
      ],
      lockValue: form.lockValue,
    });

    if (addPlayerRes.code === "CREATED_SUCCESSFULLY") {
      addPlayer(addPlayerRes.data);
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-player-modal"
      aria-describedby="add-player-form"
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "584px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#1B1C1E",
            padding: "32px 48px 40px 48px",
            borderRadius: "16px",
            color: "#fff",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#fff",
                fontFamily: "Neue Montreal",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Add New Plays
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              color: "#fff",
              marginTop: "-10px",
              fontSize: "14px",
              fontWeight: "400",
              opacity: "0.5",
            }}
          >
            Adding a new play will notify all users.
          </Typography>

          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 7,
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Input
              label="League"
              labelStyle={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "484px",
              }}
              name="league"
              value={form.league}
              onChange={handleFormChange}
            />

            {players.map((player, index) => (
              <Box key={index}>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <Input
                    label={`Player ${index + 1}`}
                    labelStyle={{
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Arial",
                    }}
                    inputStyle={{
                      backgroundColor: "#2A2A2A",
                    }}
                    value={player.player}
                    onChange={(e) => handleInputChange(e, index, "player")}
                  />
                  <Input
                    label={`Team ${index + 1}`}
                    labelStyle={{
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Arial",
                    }}
                    inputStyle={{
                      backgroundColor: "#2A2A2A",
                      width: "244px",
                    }}
                    value={player.team}
                    onChange={(e) => handleInputChange(e, index, "team")}
                  />
                </Box>
                <Input
                  sx={{ mb: 2 }}
                  label={`Reasoning ${index + 1}`}
                  labelStyle={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "Arial",
                  }}
                  inputStyle={{
                    backgroundColor: "#2A2A2A",
                    width: "484px",
                  }}
                  value={player.reasoning}
                  onChange={(e) => handleInputChange(e, index, "reasoning")}
                />
                <Input
                  label="Prop"
                  labelStyle={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "Arial",
                  }}
                  inputStyle={{
                    backgroundColor: "#2A2A2A",
                    width: "484px",
                  }}
                  name="odds"
                  value={player.odds}
                  onChange={(e) => handleInputChange(e, index, "odds")}
                  // startAdornment={
                  //   <InputAdornment position="start" sx={{ color: "#fff" }}>
                  //     <Typography
                  //       variant="span"
                  //       sx={{
                  //         paddingRight: "10px",
                  //         borderRight: "1px solid white",
                  //         marginRight: "10px",
                  //       }}
                  //     >
                  //       Over
                  //     </Typography>
                  //   </InputAdornment>
                  // }
                />
              </Box>
            ))}

            {/* <Button
              variant="outlined"
              //   onClick={handleAddPlayer}
              sx={{
                color: "#fff",
                borderColor: "#fff",
                textTransform: "none",
                borderWidth: "1px",
                borderStyle: "dashed",
                mt: 2,
                borderRadius: "5px",
                "&:hover": {
                  borderColor: "#fff",
                },
              }}
              disabled={players.length >= 2}
            >
              + Add Player
            </Button> */}

            <FormControl
              fullWidth
              sx={{
                // marginBottom: "20px",
                "& .MuiInputBase-root": {
                  paddingBlock: 0,
                },
                "& .MuiSelect-select": {
                  paddingBlock: "10px",
                  zIndex: 10,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  background: "#2a2a2a",
                  borderColor: "transparent",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#ccc",
                  marginBottom: "8px",
                  fontSize: "14px",
                }}
              >
                Lock Value
              </Typography>
              <Select
                name="lockValue"
                value={form.lockValue}
                onChange={handleFormChange}
                fullWidth
                displayEmpty
                IconComponent={CustomArrowIcon}
                sx={{
                  color: "#fff",
                  backgroundColor: "#333",

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#444",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#666",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#888",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#333", // Dark background for options
                      color: "#fff", // White text color for options
                    },
                  },
                }}
              >
                <MenuItem value={3}>3/3</MenuItem>
                <MenuItem value={2}>2/3</MenuItem>
                <MenuItem value={1}>1/3</MenuItem>
              </Select>
            </FormControl>

            {/* <Input
              label="Odds"
              labelStyle={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Arial",
              }}
              inputStyle={{
                backgroundColor: "#2A2A2A",
                width: "484px",
              }}
              name="odds"
              value={form.odds}
              onChange={handleFormChange}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#fff" }}>
                  <Typography
                    variant="span"
                    sx={{
                      paddingRight: "10px",
                      borderRight: "1px solid white",
                      marginRight: "10px",
                    }}
                  >
                    Over
                  </Typography>
                </InputAdornment>
              }
            /> */}

            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#0F0",
                padding: "10px",
                marginTop: "15px",
                color: "#111",
                textTransform: "none",
                fontFamily: "DM Sans",
                fontWeight: "500",
                fontSize: "16px",
                borderRadius: "5px",
                height: "44px",
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPlayerPopup;

const CustomArrowIcon = (props) => {
  return <KeyboardArrowDown sx={{ color: "white", zIndex: 1 }} />;
};
