import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  IconButton,
  Typography,
} from "@mui/material";
import style from "../Profile/Profile.module.css";
import { matchStore, userStore } from "../../zustand";
import Popup from "../../Component/Popup/Popup";
import { matchActions } from "../../actions";
import CancelIcon from "../../Icon/CancelIcon";
import CheckCircle from "../../Icon/CheckCircle";
import { fetchAllMatches } from "../../actions/users.actions";
import { deleteMatch } from "../../actions/matches.actions";

const History = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState({});

  // User store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  // Match Store
  const { matches, updateMatch, setMatches, removeMatch } = matchStore(
    (state) => ({
      matches: state.matches,
      updateMatch: state.updateMatch,
      setMatches: state.setMatches,
      removeMatch: state.removeMatch,
    })
  );

  useEffect(() => {
    (async () => {
      const matches = await fetchAllMatches();
      setMatches(matches);
    })();
  }, []);

  const handleIconClick = (match, action) => {
    setPopupData({
      title: "Are you sure?",
      message: "Was predicting Leakers correct",
      onConfirm: async () => {
        console.log(`${action} match: ${match.team1} vs ${match.team2}`);

        // update the match API
        const updatematchRes = await matchActions.updateMatch(match.id, {
          league: "",
          team1: match.team1,
          team2: match.team2,
          lockValue: match.lockValue,
          reasoning: match.reasoning,
          winningTeam: match.winningTeam,
          odds: match.odds,
          status: "WON",
        });

        if (updatematchRes.code === "OK") {
          updateMatch(match.id, updatematchRes.data);
        }

        setOpenPopup(false);
      },
    });
    setOpenPopup(true);
  };

  const handleWrongMatchPredict = (match, action) => {
    setPopupData({
      title: "Are you sure?",
      message: "Was predicting Leakers wrong",
      onConfirm: async () => {
        const updateMatchRes = await matchActions.updateMatch(match.id, {
          league: "",
          team1: match.team1,
          team2: match.team2,
          lockValue: match.lockValue,
          reasoning: match.reasoning,
          winningTeam: match.winningTeam,
          odds: match.odds,
          status: "LOST",
        });

        updateMatch(match.id, updateMatchRes.data);

        setOpenPopup(false);
      },
    });
    setOpenPopup(true);
  };

  return (
    <>
      {user?.role === "ADMIN" ? (
        <>
          <div
            className="container"
            style={{ width: "100%", height: "100%", marginBottom: 30 }}
          >
            <div style={{ color: "#fff", paddingTop: "61px" }}>
              <Box>
                <Typography
                  sx={{ color: "#fff" }}
                  textAlign="center"
                  padding="2rem 0 0 0"
                  fontWeight="700"
                  fontSize="32px"
                  marginBottom="61px"
                >
                  Welcome to Your Dashboard
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{ color: "#fff" }}
                  fontWeight="700"
                  fontSize="32px"
                  textAlign="left"
                >
                  Needs to update
                </Typography>
              </Box>

              <div style={{ marginBottom: "30px" }}>
                <List>
                  {matches?.map(
                    (match, i) =>
                      match.status === "PENDING" && (
                        <ListItem
                          className={style.text}
                          key={i}
                          secondaryAction={
                            <>
                              <IconButton
                                onClick={() =>
                                  handleIconClick(match, "confirm")
                                }
                                sx={{ color: "green" }}
                              >
                                <div
                                  style={{
                                    padding: "5px",
                                    borderRadius: "50%",
                                    background: "rgba(255, 255, 255, 0.08)",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CheckCircle />
                                </div>
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleWrongMatchPredict(match, "reject")
                                }
                                sx={{ color: "red" }}
                              >
                                <div
                                  style={{
                                    padding: "5px",
                                    borderRadius: "50%",
                                    background: "rgba(255, 255, 255, 0.08)",
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CancelIcon />
                                </div>
                              </IconButton>
                            </>
                          }
                        >
                          <ListItemText
                            primary={`${match.team1} vs ${match.team2} - ${match.winningTeam} - ${match.odds}`}
                          />
                        </ListItem>
                      )
                  )}
                </List>
              </div>

              <div
                style={{
                  backgroundColor: "#191919",
                  color: "#fff",
                  flex: "1 1 100%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <CardContent sx={{ padding: "0 !important" }}>
                  <Typography
                    variant="h6"
                    fontWeight="700"
                    fontSize="24px"
                    marginBottom="15px"
                  >
                    Previous Plays
                  </Typography>
                  <List>
                    {matches?.map((match, i) => {
                      return (
                        match.status !== "PENDING" && (
                          <ListItem
                            className={style.text}
                            key={i}
                            style={{
                              color:
                                match.status === "LOST" ? "#F57C7C" : "#2ECC71",
                            }}
                          >
                            <ListItemText
                              primary={`${match.team1} vs ${match.team2} - ${match.winningTeam} - ${match.odds}`}
                            />
                          </ListItem>
                        )
                      );
                    })}
                  </List>
                </CardContent>
              </div>
            </div>
          </div>

          {/* Popup Component */}
          <Popup
            open={openPopup}
            handleClose={() => setOpenPopup(false)}
            title={popupData.title}
            message={popupData.message}
            onConfirm={popupData.onConfirm}
          />
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "white", display: "inline-block" }}>
            No Permission
          </span>
        </Box>
      )}
    </>
  );
};

export default History;
