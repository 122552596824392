import * as React from "react";

const CloseSquare = (props) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="vuesax/linear/close-square">
      <g id="close-square">
        <path
          id="Vector"
          d="M9.16992 15.7017L14.8299 10.0417"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M14.8299 15.7017L9.16992 10.0417"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M9 22.8718H15C20 22.8718 22 20.8718 22 15.8718V9.87183C22 4.87183 20 2.87183 15 2.87183H9C4 2.87183 2 4.87183 2 9.87183V15.8718C2 20.8718 4 22.8718 9 22.8718Z"
          stroke="white"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
export default CloseSquare;
