import * as React from "react";

const RedLock = (props) => (
  <svg
    width={12}
    height={17}
    viewBox="0 0 12 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector"
      d="M1.5 16.5C1.0875 16.5 0.7345 16.3509 0.441 16.0528C0.1475 15.7546 0.0005 15.3957 0 14.9762V7.35714C0 6.9381 0.147 6.57949 0.441 6.28133C0.735 5.98317 1.088 5.83384 1.5 5.83333H2.25V4.30952C2.25 3.25556 2.61575 2.35727 3.34725 1.61467C4.07875 0.872064 4.963 0.500508 6 0.500001C7.037 0.499493 7.9215 0.871048 8.6535 1.61467C9.3855 2.35829 9.751 3.25657 9.75 4.30952V5.83333H10.5C10.9125 5.83333 11.2657 5.98267 11.5597 6.28133C11.8537 6.58 12.0005 6.9386 12 7.35714V14.9762C12 15.3952 11.8532 15.7541 11.5597 16.0528C11.2662 16.3514 10.913 16.5005 10.5 16.5H1.5ZM6 12.6905C6.4125 12.6905 6.76575 12.5414 7.05975 12.2432C7.35375 11.9451 7.5005 11.5862 7.5 11.1667C7.4995 10.7471 7.35275 10.3885 7.05975 10.0909C6.76675 9.79321 6.4135 9.64387 6 9.64286C5.5865 9.64184 5.2335 9.79117 4.941 10.0909C4.6485 10.3905 4.5015 10.7491 4.5 11.1667C4.4985 11.5842 4.6455 11.943 4.941 12.2432C5.2365 12.5434 5.5895 12.6925 6 12.6905ZM3.75 5.83333H8.25V4.30952C8.25 3.6746 8.03125 3.13492 7.59375 2.69048C7.15625 2.24603 6.625 2.02381 6 2.02381C5.375 2.02381 4.84375 2.24603 4.40625 2.69048C3.96875 3.13492 3.75 3.6746 3.75 4.30952V5.83333Z"
      fill="#F57C7C"
    />
  </svg>
);
export default RedLock;
