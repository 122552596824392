import React from "react";
import styled from "./NavBar.module.css";
import logo from "../../asset/logo.png";
import { Link, useLocation } from "react-router-dom";
import { userStore } from "../../zustand";
import { useMediaQuery } from "@mui/material";

const NavBar = () => {
  //! logo issue
  const location = useLocation();
  const isDesktopOrLaptop = useMediaQuery("(min-width: 767px)");

  // User Store
  const { user } = userStore((state) => ({
    user: state.user,
  }));

  const isDisabled =
    user.subscriptionStatus === "CANCELLED" && user.role !== "ADMIN";

  return (
    <div className={isDesktopOrLaptop ? styled.Nav : styled.NavBobile}>
      <div className={styled.logo}>
        <img src={logo} alt="logo" />
      </div>

      <div className={styled.title}>
        <div className={styled.NavInside}>
          {isDesktopOrLaptop && (
            <>
              <Link
                to="/"
                className={`${styled.link} ${
                  location?.pathname === "/" ? styled.hover : styled.btn
                }`}
                style={{
                  cursor: isDisabled ? "no-drop" : "pointer",

                  pointerEvents: isDisabled ? "none" : "auto",

                  opacity: isDisabled ? 0.3 : 1,
                }}
              >
                Dashboard
              </Link>

              <Link
                to="/profile"
                className={`${styled.link} ${
                  location?.pathname === "/profile" ? styled.hover : styled.btn
                }`}
                style={{
                  cursor: isDisabled ? "no-drop" : "pointer",

                  pointerEvents: isDisabled ? "none" : "auto",

                  opacity: isDisabled ? 0.3 : 1,
                }}
              >
                Profile
              </Link>

              {user?.role === "USER" && (
                <Link
                  to="/notification"
                  className={`${styled.link} ${
                    location?.pathname === "/notification"
                      ? styled.hover
                      : styled.btn
                  }`}
                  style={{
                    cursor: isDisabled ? "no-drop" : "pointer",

                    pointerEvents: isDisabled ? "none" : "auto",

                    opacity: isDisabled ? 0.3 : 1,
                  }}
                >
                  Notification
                </Link>
              )}

              {user?.role === "ADMIN" && (
                <Link
                  to="/history"
                  className={`${styled.link} ${
                    location?.pathname === "/history"
                      ? styled.hover
                      : styled.btn
                  }`}
                >
                  History
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
