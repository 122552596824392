import { matchService } from "../services";

export const fetchMatches = async () => {
	try {
		const response = await matchService.fetchMatches();
		return response;
	} catch (error) {
		return error;
	}
};

export const createMatch = async (payload) => {
	try {
		const response = await matchService.createMatch(payload);
		return response;
	} catch (error) {
		return error;
	}
};

export const deleteMatch = async (id) => {
	try {
		const response = await matchService.deleteMatch(id);

		if (response?.code !== "OK") {
			alert(response.response.data.message);
			return false;
		}

		return response;
	} catch (error) {
		return error;
	}
};

export const updateMatch = async (id, payload) => {
	try {
		const response = await matchService.updateMatch(id, payload);
		return response;
	} catch (error) {
		return error;
	}
};
