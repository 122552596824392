import * as React from "react";
const CancelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    viewBox="0 0 26 25"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1368 6.71998C20.2259 6.63101 20.2966 6.52536 20.3448 6.40908C20.3931 6.29279 20.418 6.16813 20.418 6.04223C20.4181 5.91633 20.3934 5.79165 20.3453 5.6753C20.2972 5.55896 20.2266 5.45322 20.1377 5.36414C20.0487 5.27506 19.943 5.20438 19.8268 5.15613C19.7105 5.10788 19.5858 5.083 19.4599 5.08293C19.334 5.08285 19.2093 5.10757 19.093 5.15568C18.9766 5.20379 18.8709 5.27434 18.7818 5.36331L13.0002 11.145L7.22016 5.36331C7.04026 5.18341 6.79625 5.08234 6.54183 5.08234C6.2874 5.08234 6.0434 5.18341 5.86349 5.36331C5.68359 5.54322 5.58252 5.78722 5.58252 6.04164C5.58252 6.29607 5.68359 6.54007 5.86349 6.71998L11.6452 12.5L5.86349 18.28C5.77441 18.3691 5.70375 18.4748 5.65554 18.5912C5.60733 18.7076 5.58252 18.8323 5.58252 18.9583C5.58252 19.0843 5.60733 19.209 5.65554 19.3254C5.70375 19.4418 5.77441 19.5476 5.86349 19.6366C6.0434 19.8165 6.2874 19.9176 6.54183 19.9176C6.66781 19.9176 6.79255 19.8928 6.90894 19.8446C7.02533 19.7964 7.13108 19.7257 7.22016 19.6366L13.0002 13.855L18.7818 19.6366C18.9617 19.8163 19.2056 19.9172 19.4599 19.917C19.7142 19.9169 19.958 19.8157 20.1377 19.6358C20.3173 19.4559 20.4182 19.212 20.418 18.9577C20.4179 18.7035 20.3167 18.4597 20.1368 18.28L14.3552 12.5L20.1368 6.71998Z"
      fill="white"
    />
  </svg>
);
export default CancelIcon;
