import React from "react";
import { BlackLock, Lock, RedLock, YellowLock } from "../Icon";

const Locks = ({ lockValue }) => {
  return (
    <ul
      style={{
        display: "flex",
        alignItems: "center",
        listStyle: "none",
        gap: "10px",
      }}
    >
      <li>
        {lockValue === 1 ? (
          <RedLock />
        ) : lockValue === 2 ? (
          <YellowLock />
        ) : (
          <Lock />
        )}
      </li>
      <li>
        {lockValue === 1 ? (
          <BlackLock />
        ) : lockValue === 2 ? (
          <YellowLock />
        ) : (
          <Lock />
        )}
      </li>
      <li>
        {lockValue === 1 ? (
          <BlackLock />
        ) : lockValue === 2 ? (
          <BlackLock />
        ) : (
          <Lock />
        )}
      </li>
    </ul>
  );
};

export default Locks;
