import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  // baseURL: "http://localhost:8888",
  headers: {
    "Content-Type": "application/json",
  },
});

export default Axios;
