import Axios from "../axios";
import { userService } from "../services";

export const register = async (payload) => {
  try {
    const { code, message } = await userService.registerUser(payload);

    if (code !== "CREATED_SUCCESSFULLY") {
      alert(message || "Something went wrong");
      return null;
    }

    return true;
  } catch (error) {
    console.log(error);
    alert(error.message);
    return false;
  }
};

export const verifyEmailToken = async (payload) => {
  try {
    const response = await userService.verifyEmailToken(payload);

    if (response.data?.accessToken) {
      localStorage.setItem("token", JSON.stringify(response.data));

      Axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data?.accessToken}`;
      window.location.href = "/";
    }
    return true;
  } catch (error) {
    console.log(error, "error");
    alert(error.response.data.message);
    return false;
  }
};

export const login = async (payload) => {
  try {
    const response = await userService.login(payload);

    if (response.status === 400) {
      alert(response.response.data.message);
      return null;
    }

    // if (response.code === "OK") {
    //   alert(response.message);
    //   return response;
    // }

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchUser = async (uid) => {
  try {
    const response = await userService.fetchUser(uid);
    return response;
  } catch (error) {
    alert(error.message);
    return null;
  }
};

export const updateUser = async (uid, payload) => {
  try {
    const response = await userService.updateUser(uid, payload);
    return response;
  } catch (error) {
    alert(error.message);
    return null;
  }
};

export const refreshToken = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) return;
    const userData = JSON.parse(token);

    const response = await userService.refreshToken(userData.refreshToken);

    Axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.accessToken}`;

    const updatedToken = {
      ...userData,
      uid: response.data.uid,
      accessToken: response.data.accessToken,
    };

    localStorage.setItem("token", JSON.stringify(updatedToken));

    return response.data;
  } catch (error) {
    // alert(error.message);
    return null;
  }
};

export const getPricing = async () => {
  try {
    const response = await userService.getPricing();

    const recurringProducts = response.data.filter((item) => !!item.recurring);

    const prodcuts = recurringProducts.map((item) => {
      const product = item?.product || {};

      return {
        image: product?.images?.[0] || "",
        bgColor: product?.metadata?.bgColor || "",
        heading: product?.name || "",
        desc: product?.description || "",
        price: item?.unit_amount / 100 || "",
        btnText: product?.metadata?.btnText || "",
        id: product?.id || "",
      };
    });

    return prodcuts;
  } catch (error) {
    alert(error.message);
    return null;
  }
};

export const createCheckout = async (product_id) => {
  try {
    const response = await userService.createCheckout(product_id);
    return response;
  } catch (error) {
    alert(error.message);
    return null;
  }
};

export const fetchAllMatches = async () => {
  try {
    const response = await userService.fetchAllMatches();
    return response.data;
  } catch (error) {
    alert(error.message);
    return null;
  }
};
