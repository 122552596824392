import { create } from "zustand";

const playerStore = create((set) => ({
  players: [],
  isPlayerLoading: false,
  setIsPlayerLoading: (val) =>
    set((state) => ({ ...state, isPlayerLoading: val })),
  setPlayers: (data) => set(() => ({ players: data, isPlayerLoading: false })),
  addPlayer: (player) =>
    set((state) => ({
      ...state,
      players: [...state.players, player],
      isPlayerLoading: false,
    })),
  removePlayer: (playerId) =>
    set((state) => ({
      ...state,
      players: state.players.filter((player) => player.id !== playerId),
      isPlayerLoading: false,
    })),
}));

export default playerStore;
