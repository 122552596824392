import React, { useState } from "react";

import { Button, CircularProgress } from "@mui/material";
import { userActions } from "../../actions";

import styles from "./SubscriptionCard.module.css";

const SubscriptionCard = (props) => {
  const { data } = props;

  const [loading, setLoading] = useState(false);

  const handleSubscribe = async () => {
    setLoading(true);
    const res = await userActions.createCheckout(data.id);
    setLoading(false);

    window.open(res.url, "_self");
  };

  return (
    <div className={styles.card}>
      <div
        className={styles.img}
        style={{
          background: data.bgColor,
        }}
      >
        <img src={data.image} alt="" />
      </div>
      <div className={styles.content}>
        <p className={styles.heading}>{data.heading}</p>
        <p className={styles.desc}>{data.desc}</p>
        <p className={styles.priceText}>
          Normal Price
          <span className={styles.price}>{data.price} $</span>
        </p>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#2ECC71",
            padding: "10px",
            color: "#000",
            fontFamily: "Work Sans",
            fontWeight: "600",
            fontSize: "18px",
            borderRadius: "32px",
            height: "53px",
            width: "252px",
          }}
          type="submit"
          onClick={handleSubscribe}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: "#000" }} />
          ) : (
            data.btnText
          )}
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
