import * as React from "react";

const Notification = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={22}
    viewBox="0 0 18 22"
    fill="none"
    {...props}
  >
    <path
      d="M5.645 19.251C5.86103 19.9729 6.30417 20.6059 6.90858 21.0559C7.513 21.5059 8.24645 21.749 9 21.749C9.75355 21.749 10.487 21.5059 11.0914 21.0559C11.6958 20.6059 12.139 19.9729 12.355 19.251H5.645ZM0 18.251H18V15.251L16 12.251V7.25098C16 6.33172 15.8189 5.42147 15.4672 4.57219C15.1154 3.72291 14.5998 2.95124 13.9497 2.30123C13.2997 1.65122 12.5281 1.1356 11.6788 0.78382C10.8295 0.432037 9.91925 0.250977 9 0.250977C8.08075 0.250977 7.17049 0.432037 6.32122 0.78382C5.47194 1.1356 4.70026 1.65122 4.05025 2.30123C3.40024 2.95124 2.88463 3.72291 2.53284 4.57219C2.18106 5.42147 2 6.33172 2 7.25098V12.251L0 15.251V18.251Z"
      fill={props.fill || "#656565"}
    />
  </svg>
);
export default Notification;
