import React from 'react'
import Inbox from './InboxView'

const InboxComponent = () => {
  return (
    <div>
        <Inbox/>
    </div>
  )
}

export default InboxComponent