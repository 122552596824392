import { Box } from "@mui/material";
import React from "react";
import { Dashboard, Notification, Profile } from "../Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { userStore } from "../zustand";

const BottomBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = userStore((state) => ({
    user: state.user,
  }));

  const isDisabled =
    user?.subscriptionStatus === "CANCELLED" && user?.role !== "ADMIN";

  const menu = [
    {
      icon: (
        <Dashboard fill={location.pathname === "/" ? "#2ECC71" : "#A4A4A4"} />
      ),
      label: "Dashboard",
      path: "/",
    },
    {
      icon: (
        <Profile
          fill={location.pathname === "/profile" ? "#2ECC71" : "#A4A4A4"}
        />
      ),
      label: "Profile",
      path: "/profile",
    },
    {
      icon: (
        <Notification
          fill={location.pathname === "/notification" ? "#2ECC71" : "#A4A4A4"}
        />
      ),
      label: "Notification",
      path: "/notification",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "90px",
        background: "#111",
        position: "fixed",
        zIndex: 100,
        bottom: 0,
        paddingBlock: "10px",
        paddingInline: "30px",
        display: "flex",
        justifyContent: "center",
        gap: "12px",
      }}
    >
      {menu.map((item) => (
        <Box
          key={item.label}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: "8px 10px",
            alignItems: "center",
            justifyContent: "center",
            color: "#A4A4A4",
            backgroundColor: "#fff",
            borderRadius: "15px",
            width: "100px",
            height: "70px",
            backgroundColor:
              location.pathname === item.path ? "#242424" : "transparent",
            opacity: isDisabled ? 0.3 : 1,
          }}
          onClick={() => {
            if (isDisabled) return;
            navigate(item.path);
          }}
        >
          {item.icon}
          <span
            style={{
              color: location.pathname === item.path ? "#2ECC71" : "#A4A4A4",
            }}
          >
            {item.label}
          </span>
        </Box>
      ))}
    </Box>
  );
};

export default BottomBar;
