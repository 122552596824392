import Axios from "../axios";

export const registerUser = async ({ email, username }) => {
  try {
    const response = await Axios.post("/users/register", { email, username });
    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const verifyEmailToken = async ({ token }) => {
  const response = await Axios.post(`/users/verify-email`, {
    token,
  });
  return response.data;
};

export const login = async (payload) => {
  try {
    const response = await Axios.post("/users/login", payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchUser = async (uid) => {
  try {
    const response = await Axios.get(`/users/${uid}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateUser = async (uid, payload) => {
  try {
    const response = await Axios.post(`/users/${uid}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const refreshToken = async (refreshToken) => {
  try {
    const response = await Axios.post(`/users/refresh-token`, { refreshToken });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPricing = async () => {
  try {
    const response = await Axios.get(`/users/pricing`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createCheckout = async (product_id) => {
  try {
    const response = await Axios.post(`/users/create-checkout`, { product_id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchAllMatches = async () => {
  try {
    const response = await Axios.post(`/matches/all`);
    return response.data;
  } catch (error) {
    return error;
  }
};
