import React from "react";
import CartComponent from "./CartComponet/CartComponet";
import { Grid, Box } from "@mui/material";
import { matchStore } from "../zustand";
import LoadingPage from "./LoadingPage";

const MatchPanel = () => {
  // Match Store
  const { matches, isMatchLoading } = matchStore((state) => ({
    matches: state.matches,
    isMatchLoading: state.isMatchLoading,
  }));

  if (isMatchLoading) {
    return <LoadingPage />;
  }

  return (
    <Box
      sx={{
        // maxHeight: "calc(100vh - 300px)",
        height: "100%",
        marginBottom: "80px",
        width: "100%",
      }}
    >
      {matches.length ? (
        <Grid container spacing={2}>
          {matches?.map((match, index) => {
            return (
              <Grid key={match.id} item md={6} xs={12}>
                <CartComponent match={match} />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box
          sx={{
            color: "white",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No data found
        </Box>
      )}
    </Box>
  );
};

export default MatchPanel;
