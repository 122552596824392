import React from "react";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	Button,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const Popup = ({
	open,
	handleClose,
	title = "Confirmation",
	message = "Are you sure you want to proceed?",
	confirmText = "Yes",
	cancelText = "Cancel",
	onConfirm,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="dynamic-modal"
			aria-describedby="dynamic-confirmation"
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "#1B1C1E",
					padding: isMobile ? "16px 24px" : "32px 48px",
					borderRadius: "16px",
					width: "100%",
					maxWidth: isMobile ? "320px" : "584px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					color: "#fff",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						mb: isMobile ? 2 : 4,
					}}
				>
					<Typography
						variant="h1"
						sx={{
							color: "#fff",
							fontFamily: "Neue Montreal",
							fontSize: isMobile ? "16px" : "20px",
							fontWeight: "500",
							flex: 1,
						}}
					>
						{title}
					</Typography>
					<IconButton onClick={handleClose} sx={{ color: "#fff", padding: 0 }}>
						<Close />
					</IconButton>
				</Box>

				<Typography
					sx={{
						color: "#fff",
						fontSize: isMobile ? "12px" : "14px",
						fontWeight: "400",
						opacity: "0.5",
						textAlign: "left",
						marginTop: "-10px",
						fontFamily: "DM Sans",
						width: "100%",
						mb: isMobile ? 2 : 4,
					}}
				>
					{message}
				</Typography>

				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						gap: isMobile ? 2 : 3,
						width: "100%",
					}}
				>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#2F3034",
							padding: isMobile ? "8px 16px" : "10px 24px",
							color: "#fff",
							textTransform: "none",
							fontFamily: "DM Sans",
							fontWeight: "500",
							fontSize: isMobile ? "14px" : "16px",
							borderRadius: "100px",
							width: "100%",
							"&:hover": {
								backgroundColor: "#2F3034",
							},
						}}
						onClick={handleConfirm}
					>
						{confirmText}
					</Button>

					<Button
						variant="contained"
						sx={{
							backgroundColor: "#2ECC71",
							padding: isMobile ? "8px 16px" : "10px 24px",
							color: "#000",
							textTransform: "none",
							fontFamily: "DM Sans",
							fontWeight: "500",
							fontSize: isMobile ? "14px" : "16px",
							borderRadius: "100px",
							width: "100%",
							"&:hover": {
								backgroundColor: "#2ECC71",
							},
						}}
						onClick={handleClose}
					>
						{cancelText}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default Popup;
