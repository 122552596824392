import React from "react";
import Logo from "../../asset/logo.png";

const Inbox = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#000",
        margin: "0 20px",
      }}
    >
      <div
        style={{
          width: "380px",
          backgroundColor: "#2A2A2A",
          padding: "24px 40px 56px 40px",
          borderRadius: "18px",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={Logo} alt="Logo" style={{ marginBottom: "10px" }} />
          <h1
            style={{
              color: "#FFF",
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "500",
              fontFamily: "Neue Montreal",
              marginBottom: "13px",
              lineHeight: "28px",
              marginTop: "20px",
            }}
          >
            Check your inbox
          </h1>

          <p
            style={{
              color: "#FFF",
              fontSize: "14px",
              fontWeight: "400",
              opacity: "0.6",
              lineHeight: "24px",
              marginTop: "13px",
              fontFamily: "DM Sans",
              maxWidth: "272px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            To continue, head over to your inbox and click on the verification
            link we just sent you.
          </p>
        </div>

        <button
          style={{
            width: "100%",
            padding: "12px 16px",
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
            fontSize: "16px",
            marginTop: "10px",
            color: "#fff",
          }}
        >
          Resend
        </button>
      </div>
    </div>
  );
};

export default Inbox;
